import React, { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import SelectClass from "../ChildComponent/SelectClass";
import COLORS from "../resources/colors/COLORS";
import myToast from "../Utils/MyToast";
import SessionManager from "../Utils/SessionManager";

const AlternateSubject = () => {
  const [isOpenAddSubjectDialog, setIsOpenAddSubjectDialog] = useState(false);
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [classId, setClassId] = useState("");
  const [className, setClassName] = useState("");
  const [sectionId, setSectionId] = useState("");
  const [mClassSubject, setMClassSubject] = useState([]);
  const [selectedClassPosition, setSelectedClassPosition] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleShowDialog = () => {
    setIsOpenAddSubjectDialog(!isOpenAddSubjectDialog);
  };

  const callBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name
  ) => {
    setClassId(class_id);
    setClassName(class_name);
    setSectionId(section_id);
    setSessionId(session_id);
    setSelectedClassPosition(selected_position);
    getClassSubject(class_id, session_id);
  };

  const getClassSubject = (class_id, session_id) => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = "getClassSubject.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      API_NAME;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          session_id,
          class_id,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        const { api_status, data, api_remark } = response;
        if (api_status === "OK") {
          setMClassSubject(data);
        } else {
          myToast.notify_warning(api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        path={"/AdminMain/AlternateSubject"}
        exact
        render={() => (
          <div className="content" style={{ background: COLORS.white }}>
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#"> Class Subject </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="bg_page_grey_rounded box_shadow"
              style={{ "--mMargin": "50px" }}
            >
              <SelectClass action={callBackClassSelect} selected_position="0" />

              <div className="AlternateSubject-container">
                <div className="AlternateSubject-subject-box">
                  <h2 className="AlternateSubject-title">
                    Select Alternative Subject
                  </h2>
                  <div className="AlternateSubject-subject-buttons">
                    <button className="AlternateSubject-subject-button active">
                      Marathi
                    </button>
                    <button className="AlternateSubject-subject-button">
                      বাংলা
                    </button>
                  </div>
                </div>
                <div className="AlternateSubject-row-box">
                  <div className="AlternateSubject-row-container">
                    <div className="AlternateSubject-row">
                      <div className="AlternateSubject-card selected">
                        <div className="AlternateSubject-card-content">
                          <strong>AATIBA MUSKAN (S001811)</strong>
                          <p>Father: JAVED IQBAL</p>
                          <p>Roll No: 1</p>
                        </div>
                        <div className="AlternateSubject-status">✔</div>
                      </div>

                      <div className="AlternateSubject-card not-selected">
                        <div className="AlternateSubject-card-content">
                          <strong>ABRAZ CHOUDARY (S000211)</strong>
                          <p>Father: IMTIAZ AHMED</p>
                          <p>Roll No: 2</p>
                        </div>
                        <div className="AlternateSubject-status">✖</div>
                      </div>
                    </div>

                    <div className="AlternateSubject-row">
                      <div className="AlternateSubject-card selected">
                        <div className="AlternateSubject-card-content">
                          <strong>ANISBA NASEER (S001911)</strong>
                          <p>Father: NASEER AHMED</p>
                          <p>Roll No: 6</p>
                        </div>
                        <div className="AlternateSubject-status">✔</div>
                      </div>

                      <div className="AlternateSubject-card selected">
                        <div className="AlternateSubject-card-content">
                          <strong>Aman Kumar (S058011)</strong>
                          <p>Father: Rabi</p>
                          <p>Roll No: 7</p>
                        </div>
                        <div className="AlternateSubject-status">✔</div>
                      </div>
                    </div>
                  </div>
                </div>

                <button className="AlternateSubject-submit-button">
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      />
    </Router>
  );
};

export default AlternateSubject;
