import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
} from "react-router-dom";
import SelectClass from "../ChildComponent/SelectClass";
import COLORS from "../resources/colors/COLORS";
import boyImage from "../img/boy.jpg";
import SessionManager from "../Utils/SessionManager";
import myToast from "../Utils/MyToast";
import MyLoader from "../Spinner/MyLoader";

const StudentSubject = () => {
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [sessionID, setSessionID] = useState("");
  const [classID, setClassID] = useState("");
  const [className, setClassName] = useState("");
  const [sectionID, setSectionID] = useState("");
  const [selectedClassPosition, setSelectedClassPosition] = useState("");
  const [mStudentSubject, setStudentSubject] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const callBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name,
    session_name
  ) => {
    setClassID(class_id);
    setClassName(class_name);
    setSectionID(section_id);
    setSessionID(session_id);
    setSelectedClassPosition(selected_position);
    getClassStudentSubject(class_id, section_id, session_id);
  };

  const getClassStudentSubject = (class_id, section_id, session_id) => {
    setLoading(true);
    setStudentSubject([]);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const API_NAME = "getClassStudentSubject.php?";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      API_NAME;
    const params = {
      school_code: SCHOOL_CODE,
      session_id: session_id,
      class_id: class_id,
      section_id: section_id,
    };
    const queryString = new URLSearchParams(params).toString();
    console.log("Full URL: ", `${URL}${queryString}`);

    axios
      .get(URL, {
        params: params,
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        //console.log("Response2>>>>>", response);

        if (response.api_status === "OK") {
          setStudentSubject(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error>>>>>", error);
      });
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <Router>
      <Route
        path={"/AdminMain/StudentSubject"}
        exact
        render={() => (
          <div className="content" style={{ background: COLORS.white }}>
            <div className="content-header">
              <div className="leftside-content-header">
                <ul className="breadcrumbs">
                  <li>
                    <i className="fa fa-home" aria-hidden="true" />
                    <a href="#"> Student Subject </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="bg_page_grey_rounded box_shadow"
              style={{ padding: "10px 25px 25px 25px", "--mMargin": "80px" }}
            >
              <SelectClass action={callBackClassSelect} selected_position="0" />
              {loading ? <MyLoader /> : <></>}

              <div className="StudentSubject-container">
                {/* Student 1 */}

                {mStudentSubject &&
                  mStudentSubject.map((item, index) => {
                    var mMainSub = [];
                    var mAltSub = [];
                    mMainSub = item.main_subject;
                    mAltSub = item.alt_subject;
                    return (
                      <div className="StudentSubject-row">
                        <div className="StudentSubject-info">
                          <img
                            src={boyImage}
                            alt="Student"
                            className="StudentSubject-avatar"
                          />
                          <div className="StudentSubject-details">
                            <h3>
                              {item.stu_name +
                                "(" +
                                item.student_enrollment_no +
                                ")"}{" "}
                            </h3>
                            <p>{item.father_name}l</p>
                            <p>
                              {item.section_name != null
                                ? item.class_name + ":" + item.section_name
                                : item.class_name}
                            </p>
                          </div>
                          <div className="StudentSubject-box">
                            <h4>Main Subjects(By Default)</h4>
                            <div className="StudentSubject-subjects">
                              {mMainSub &&
                                mMainSub.map((item_main, index2) => {
                                  return (
                                    <span className="StudentSubject-subject StudentSubject-static">
                                      {item_main.subject_name}
                                    </span>
                                  );
                                })}
                            </div>
                          </div>
                        </div>

                        <div className="StudentSubject-box">
                          <h4>Alternative Subjects</h4>
                          <div className="StudentSubject-subjects">
                            {mAltSub &&
                              mAltSub.map((item_alt, index3) => {
                                return (
                                  <span
                                    className="StudentSubject-subject StudentSubject-unselected"
                                    onClick={() =>
                                      console.log("Toggle Marathi")
                                    }
                                  >
                                    {item_alt.subject_name}
                                  </span>
                                );
                              })}
                          </div>
                        </div>

                        <button className="StudentSubject-update-button">
                          Update
                        </button>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      />
    </Router>
  );
};

export default StudentSubject;
