import React, { useState, useEffect } from "react";
import "../css/Admin_css/admin-customize.css";
import { Redirect, Route } from "react-router-dom";
import axios from "axios";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import SessionManager from "../Utils/SessionManager";
import MyLoader from "../Spinner/MyLoader";
import myToast from "../Utils/MyToast";
import Form from "react-bootstrap/Form";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const StaffRegistration = () => {
  const token = localStorage.getItem("token");
  const [loggedIn, setLoggedIn] = useState(token != null);
  const [loading, setLoading] = useState(false);
  const [staffName, setStaffName] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [dob, setDob] = useState("");
  const [doj, setDoj] = useState("");
  const [inlineCssRadio1, setInlineCssRadio1] = useState("");
  const [inlineCssRadio2, setInlineCssRadio2] = useState("");
  const [phone, setPhone] = useState("");
  const [stRegion, setStRegion] = useState("");
  const [adharNo, setAdharNo] = useState("");
  const [nationality, setNationality] = useState("");
  const [village, setVillage] = useState("");
  const [post, setPost] = useState("");
  const [district, setDistrict] = useState("");
  const [gender, setGender] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [loginType, setLoginType] = useState("");
  const [staffRole, setStaffRole] = useState("");
  const [degree, setDegree] = useState("");
  const [religion, setReligion] = useState("");
  const [university, setUniversity] = useState("");
  const [year, setYear] = useState("");
  const [percentage, setPercentage] = useState("");
  const [mDepartment, setMDepartment] = useState([]);
  const mStaffRole = [
    "Teacher",
    "Clerk",
    "Class Teacher",
    "Clerk And Techer",
    "Director And Principal",
    "Director And Voice Principal",
    "Faculty",
    "LDA",
    "UDA",
    "Tutor And LDA",
    "Academic Incharge",
    "Child Caretaker",
    "Mother Teacher",
    "Computer Operator",
    "Head Master",
    "Physical Teacher",
    "Office Bearers",
    "Driver",
    "Helper",
  ];

  const handleChangeDepartment = (e) => {
    const departmentId =
      e.target.selectedOptions[0].getAttribute("department_id");
    setDepartmentId(departmentId);
    alert(departmentId);
  };

  const handleChangeStaffRole = (e) => {
    setStaffRole(e.target.value);
    alert(e.target.value);
  };

  const handleChangeloginType = (e) => {
    setLoginType(e.target.value);
    alert(e.target.value);
  };

  const handleChangeReligion = (e) => {
    setReligion(e.target.value);
    alert(e.target.value);
  };
  const handleChangeGender = (e) => {
    setGender(e.target.value);
    alert(e.target.value);
  };

  useEffect(() => {
    getDepartment();
  }, []);

  const getDepartment = () => {
    setLoading(true);
    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getDepartment.php";
    const URL =
      process.env.REACT_APP_SERVER_NAME +
      process.env.REACT_APP_TARGET_DIR +
      FUNCTION_NAME;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoading(false);
        if (response.api_status === "OK") {
          setMDepartment(response.department);
        } else {
          // Handle the API remark here
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const regChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "staffName":
        setStaffName(value);
        break;
      case "dob":
        setDob(value);
        break;
      case "doj":
        setDoj(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "adharNo":
        setAdharNo(value);
        break;
      case "nationality":
        setNationality(value);
        break;
      case "village":
        setVillage(value);
        break;
      case "post":
        setPost(value);
        break;
      case "district":
        setDistrict(value);
        break;
      case "state":
        setState(value);
        break;
      case "pincode":
        setPincode(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "religion":
        setReligion(value);
        break;
      default:
        break;
    }
  };

  const regSubmit = () => {
    if (isValidInput()) {
      const SCHOOL_CODE = SessionManager.getSchoolCode();
      const FUNCTION_NAME = "registerStaff.php?";
      const URL =
        process.env.REACT_APP_SERVER_NAME +
        process.env.REACT_APP_TARGET_DIR +
        FUNCTION_NAME;

      axios
        .get(URL, {
          params: {
            school_code: SCHOOL_CODE,
            StaffName: staffName,
            department_id: departmentId,
            dob,
            doj,
            religion: stRegion,
            gender: inlineCssRadio1 + inlineCssRadio2,
            aadhar_no: adharNo,
            nationality,
            role: staffRole,
            login_type: loginType,
            address: `${village}${post}${district}${state}${pincode}`,
            phone,
            email,
          },
        })
        .then((res) => res.data)
        .then((response) => {
          setLoading(false);
          if (response.api_status === "OK") {
            alert("Successfully Updated!");
            window.location.reload(false);
            window.location.href = "../AdminMain/StaffList";
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const isValidInput = () => {
    if (!staffName) {
      alert("Your Staff name must contain only alphabets!");
      return false;
    }
    if (!departmentId) {
      alert("Please select a Department!");
      return false;
    }
    if (!dob) {
      alert("Please enter a valid Date of Birth!");
      return false;
    }
    if (!doj) {
      alert("Please enter a valid Date of Joining!");
      return false;
    }
    if (!inlineCssRadio1 && !inlineCssRadio2) {
      alert("Please select your Gender!");
      return false;
    }
    if (!adharNo) {
      alert("Please enter a valid Aadhar Number!");
      return false;
    }
    if (!nationality) {
      alert("Please enter your Nationality!");
      return false;
    }
    if (!stRegion) {
      alert("Please enter your Religion!");
      return false;
    }
    if (!village) {
      alert("Please enter your Village!");
      return false;
    }
    if (!post) {
      alert("Please enter your Post!");
      return false;
    }
    if (!district) {
      alert("Please enter your District!");
      return false;
    }
    if (!state) {
      alert("Please enter your State!");
      return false;
    }
    if (!pincode) {
      alert("Please enter your Pincode!");
      return false;
    }
    if (!phone) {
      alert("Please enter your Phone Number!");
      return false;
    }
    if (!staffRole) {
      alert("Please enter your Staff Role!");
      return false;
    }
    if (!loginType) {
      alert("Please enter your Login Type!");
      return false;
    }
    return true;
  };

  return (
    <div>
      {loading && <MyLoader></MyLoader>}
      <div className="content">
        <div className="content-header">
          <div className="leftside-content-header">
            <ul className="breadcrumbs">
              <li>
                <i className="fa fa-home" aria-hidden="true" />
                <a href="#">Staff Registration</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row animated fadeInUp">
          <div className="col-sm-12 col-lg-12">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="panel">
                  <div
                    className="panel-content text-center"
                    style={{ minHeight: "400px" }}
                  >
                    <div className="panel-body">
                      <div className="pnlSubHedingBorder">
                        <div className="pnlSubHeding heading-with-bg-w">
                          Personal Details
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3 bg-white mar_bottom mar_top1 ">
                          <div class="circular_image1">
                            <img
                              src="https://clevelandpolicefoundation.org/wp-content/uploads/2012/09/female-placeholder.png"
                              className="img_staff1"
                              alt="student list image"
                            />
                          </div>

                          <div className="photo-row-bottom posi-rel mar_top1">
                            <input
                              type="file"
                              name="upload photo"
                              style={{ width: "168px" }}
                            />
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Staff Name</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input
                                      type="text"
                                      required
                                      id="staffName"
                                      name="staff name"
                                      variant="outlined"
                                      placeholder="Staff Name"
                                      value={staffName}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Department </label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="admission_form2">
                                    <Form.Select
                                      className="staff_role staff_mar"
                                      onChange={handleChangeDepartment}
                                    >
                                      <option>Select Department</option>
                                      {mDepartment.map((item, index) => (
                                        <option
                                          value={item.department_name}
                                          department_id={item.department_id}
                                        >
                                          {item.department_name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Staff Role </label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="admission_form2">
                                    <Form.Select
                                      className="staff_role staff_mar"
                                      onChange={handleChangeStaffRole}
                                    >
                                      <option>Select Role</option>
                                      {mStaffRole.map((item, index) => (
                                        <option value={item}>{item}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Login Type </label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="admission_form2">
                                    <Form.Select
                                      className="staff_role staff_mar"
                                      onChange={handleChangeloginType}
                                    >
                                      <option>login Type</option>
                                      <option value="Admin">Admin</option>
                                      <option value="Teacher">Teacher</option>
                                      <option value="Clerk">Clerk</option>
                                      <option value="None">None</option>
                                    </Form.Select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 mar_top1">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Phone Number</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input
                                      type="tel"
                                      required
                                      id="phone"
                                      name=""
                                      placeholder="Phone Number"
                                      value={phone}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 mar_top1">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Email Address</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input
                                      type="email"
                                      required
                                      id="email"
                                      name=""
                                      placeholder="Email id"
                                      value={email}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Date of Joining</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input
                                      type="date"
                                      required
                                      id="doj"
                                      name="doj"
                                      placeholder="Date of Joining"
                                      value={doj}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Date of Birth</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input
                                      type="date"
                                      required
                                      id="dob"
                                      name="dob"
                                      placeholder="Date of Birth"
                                      value={dob}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0 mar_top1">
                                  <label className=""> Gender </label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="d-flex">
                                    <RadioGroup
                                      aria-label="position"
                                      name="radio_css_inline"
                                      id="inlineCssRadio"
                                      value={gender}
                                      onChange={handleChangeGender}
                                      row
                                    >
                                      <FormControlLabel
                                        value="Male"
                                        control={<Radio color="primary" />}
                                        label="Male"
                                        labelPlacement="end"
                                      />
                                      <FormControlLabel
                                        value="Female"
                                        control={<Radio color="primary" />}
                                        label="Female"
                                        labelPlacement="end"
                                      />
                                    </RadioGroup>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Religion</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="admission_form2">
                                    <Form.Select
                                      className="staff_role staff_mar"
                                      onChange={handleChangeReligion}
                                    >
                                      <option>Select Religion</option>
                                      <option value="Hindu">Hindu</option>
                                      <option value="Muslim">Muslim</option>
                                      <option value="Christian">
                                        Christian
                                      </option>
                                      <option value="Sikh">Sikh</option>
                                      <option value="Buddhist">Buddhist</option>
                                      <option value="Jain">Jain</option>
                                      <option value="Other">Other</option>
                                    </Form.Select>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Adhar Number</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input
                                      type="text"
                                      required
                                      id="adharNo"
                                      name=""
                                      placeholder="Adhar Number"
                                      value={adharNo}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Nationality</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <input
                                      type="text"
                                      required
                                      id="nationality"
                                      name="nationality"
                                      placeholder="Nationality"
                                      value={nationality}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ float: "left", width: "100%" }}>
                        <div className="pnlSubHedingBorder headMarginTop">
                          <div className="pnlSubHeding heading-with-bg-w">
                            Address
                          </div>
                        </div>
                        <div className="form-horizontal form-bordered">
                          <div className="form-group row">
                            <label className="col-md-2  col-form-label">
                              {" "}
                              Village/Town{" "}
                            </label>
                            <div className="col-md-10 ">
                              <div className="input-group">
                                <input
                                  id="Village"
                                  value={village}
                                  onChange={regChange}
                                  type="text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <label className="col-md-2  col-form-label">
                              {" "}
                              Post{" "}
                            </label>
                            <div className="col-md-4 ">
                              <div className="input-group">
                                <input
                                  id="post"
                                  type="text"
                                  value={post}
                                  onChange={regChange}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <label className="col-md-2  col-form-label">
                              {" "}
                              District{" "}
                            </label>
                            <div className="col-md-4 ">
                              <div className="input-group">
                                <input
                                  id="district"
                                  type="text"
                                  value={district}
                                  onChange={regChange}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <label className="col-md-2  col-form-label">
                              {" "}
                              State{" "}
                            </label>
                            <div className="col-md-4 ">
                              <div className="input-group">
                                <input
                                  id="State"
                                  type="text"
                                  value={state}
                                  onChange={regChange}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <label className="col-md-2  col-form-label">
                              {" "}
                              Pin Code{" "}
                            </label>
                            <div className="col-md-4 ">
                              <div className="input-group">
                                <input
                                  id="Pincode"
                                  type="text"
                                  value={pincode}
                                  onChange={regChange}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ float: "left", width: "100%" }}>
                        <div className="pnlSubHedingBorder headMarginTop">
                          <div className="pnlSubHeding heading-with-bg-w">
                            Education Details
                          </div>
                        </div>
                        <div className="bg_card_blue_outline">
                          <div className="row mar_top1">
                            <div className="col-md-4">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Degree/Diploma</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="admission_form2">
                                    <h4 className="staff1"> B.tech</h4>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-8">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">
                                    University/Institute
                                  </label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <h4> prasad polytechnic jaunpur</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Year</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="admission_form2">
                                    <h4 className="staff2">2020</h4>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-4 padding_0">
                                  <label className="">Percentage</label>
                                </div>
                                <div className="col-md-8 padding_0">
                                  <div className="input-group admission_form2">
                                    <h4>98%</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mar_top1">
                          <button
                            style={{
                              width: "20%",
                              borderRadius: "10px",
                              fontSize: "14px",
                              padding: "4px",
                              background: "#28a745",
                              float: "right",
                            }}
                            data-toggle="modal"
                            data-target="#exampleModal"
                          >
                            {" "}
                            Add Next Qualification
                          </button>
                        </div>
                      </div>

                      {/* add modal degree and university section */}

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div
                            className="modal-content"
                            style={{
                              width: "810px",
                              height: "350px",
                              padding: "40px",
                            }}
                          >
                            <div
                              className="modal-header"
                              style={{ background: "#516d8b", color: "#fff" }}
                            >
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Add Qualification section
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span
                                  aria-hidden="true"
                                  style={{ color: "#fff" }}
                                >
                                  &times;
                                </span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <label className="col-md-2  col-form-label">
                                  {" "}
                                  Degree/Diploma{" "}
                                </label>
                                <div className="col-md-4 ">
                                  <div className="input-group">
                                    <input
                                      id="Degree"
                                      type="text"
                                      value={degree}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <label className="col-md-2  col-form-label">
                                  University/Institute{" "}
                                </label>
                                <div className="col-md-4 ">
                                  <div className="input-group">
                                    <input
                                      id="University"
                                      type="text"
                                      value={university}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <label className="col-md-2  col-form-label">
                                  Year{" "}
                                </label>
                                <div className="col-md-4 ">
                                  <div className="input-group">
                                    <input
                                      id="year"
                                      type="text"
                                      value={year}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <label className="col-md-2  col-form-label">
                                  Percentage{" "}
                                </label>
                                <div className="col-md-4 ">
                                  <div className="input-group">
                                    <input
                                      id="percentage"
                                      type="text"
                                      value={percentage}
                                      onChange={regChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer s-pad-30">
                              <button
                                type="button"
                                class="btn btn-primary s-change"
                              >
                                {" "}
                                Add Qualification{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ float: "left", width: "100%" }}>
                        <div className=" row">
                          <div className="col-md-12 padding_0">
                            <button
                              className="btn btn-success"
                              onClick={regSubmit}
                              style={{
                                margin: "2% 0px",
                                fontSize: "20px",
                                width: "100%",
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffRegistration;
