import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MyLoader from "../Spinner/MyLoader";
import SessionManager from "../Utils/SessionManager";
import { Link, Redirect } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "../css/Admin_css/admin-customize.css";
import COLORS from "../resources/colors/COLORS";
import myToast from "../Utils/MyToast";
import SelectClass from "../ChildComponent/SelectClass";

const MdlSelectMultipleStudent = ({ actionClose, actionHandleSelected }) => {
  const slt_board = useRef();
  const slt_medium = useRef();
  const slt_class = useRef();
  const slt_section = useRef();

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("token") !== null
  );
  const [loading, setLoading] = useState(false);
  const [loadingStudent, setLoadingStudent] = useState(false);
  const [show, setShow] = useState(true);
  const [selectedClassPosition, setSelectedClassPosition] = useState(0);
  const [studentList, setStudentList] = useState([]);
  const [mSelectedStudent, setMSelectedStudent] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  useEffect(() => {
    localStorage.setItem("SelectedMultipleStudent", JSON.stringify([]));
  }, []);

  const callBackClassSelect = (
    class_id,
    section_id,
    session_id,
    selected_position,
    class_name,
    section_name,
    session_name
  ) => {
    setMSelectedStudent([]);
    localStorage.setItem("SelectedMultipleStudent", JSON.stringify([]));
    setSelectAll(false);

    setSelectedClassPosition(selected_position);
    getStudentList(class_id, section_id, session_id);
  };

  const getStudentList = (class_id, section_id, session_id) => {
    setLoadingStudent(true);
    setStudentList([]);

    const SCHOOL_CODE = SessionManager.getSchoolCode();
    const FUNCTION_NAME = "getCurrentStudent.php?";
    const URL = `${process.env.REACT_APP_SERVER_NAME}${process.env.REACT_APP_TARGET_DIR}${FUNCTION_NAME}`;

    axios
      .get(URL, {
        params: {
          school_code: SCHOOL_CODE,
          class_id,
          section_id,
          session_id,
        },
      })
      .then((res) => res.data)
      .then((response) => {
        setLoadingStudent(false);
        if (response.api_status === "OK") {
          setStudentList(response.data);
        } else {
          myToast.notify_warning(response.api_remark);
        }
      })
      .catch((error) => {
        setLoadingStudent(false);
        console.error("Error fetching students:", error);
      });
  };

  const handleStudentSelection = (e, index) => {
    const isChecked = e.target.checked;
    const student = studentList[index];
    let updatedSelectedStudents = [...mSelectedStudent];

    if (isChecked) {
      updatedSelectedStudents.push(student);
    } else {
      updatedSelectedStudents = updatedSelectedStudents.filter(
        (s) => s.student_enrollment_no !== student.student_enrollment_no
      );
    }

    setMSelectedStudent(updatedSelectedStudents);
    localStorage.setItem(
      "SelectedMultipleStudent",
      JSON.stringify(updatedSelectedStudents)
    );
  };
  const handleSelectAll = () => {
    if (!selectAll) {
      setMSelectedStudent(studentList);
      localStorage.setItem(
        "SelectedMultipleStudent",
        JSON.stringify(studentList)
      );
    } else {
      setMSelectedStudent([]);
      localStorage.setItem("SelectedMultipleStudent", JSON.stringify([]));
    }
    setSelectAll(!selectAll);
  };

  if (!loggedIn) {
    return <Redirect to="/Login" />;
  }

  return (
    <div>
      <Modal
        show={show}
        onHide={actionClose}
        animation={false}
        style={{ opacity: 1 }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{
            background: COLORS.primary_color_shade7,
            color: COLORS.white,
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            Select Student
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <MyLoader />
          ) : (
            <div>
              <SelectClass
                action={callBackClassSelect}
                selected_position={selectedClassPosition}
              />
              <div className="row">
                <div className="col-sm-8 col-md-8 ">
                  <div className="text-center">
                    <h3>
                      Selected Students: {mSelectedStudent.length}{" "}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </h3>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 gravity_center">
                  <button
                    className="btn btn-primary margin_0"
                    onClick={handleSelectAll}
                    style={{ marginLeft: "auto" }}
                  >
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>
                </div>
              </div>
              <div className="row bg_card_blue_outline">
                {loadingStudent ? (
                  <MyLoader />
                ) : (
                  studentList.map((item, index) => (
                    <div
                      className="col-sm-6 col-md-6 p-0"
                      key={item.student_enrollment_no}
                    >
                      <div
                        className="row bg_card_blue_outline"
                        style={{ margin: "5px 5px 10px 5px", height: "90px" }}
                      >
                        <div
                          className="col-sm-3 bg_card_blue gravity_center"
                          style={{ padding: "1px", height: "85px" }}
                        >
                          <div
                            className="gravity_center"
                            style={{
                              padding: "0px",
                              margin: "2px",
                              height: "80px",
                              width: "80px",
                            }}
                          >
                            <div className="circular_image">
                              <img src={item.student_img_path} alt="" />
                              <p className="student_id gravity_center">
                                <small>{item.student_enrollment_no}</small>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-sm-6"
                          style={{ padding: "0px 2px 0px 2px", height: "85px" }}
                        >
                          <h6 className="mb-0">{item.stu_name}</h6>
                          <p className="mb-0">
                            Father: <span>{item.father_name}</span>
                          </p>
                          <p className="mb-0">
                            Village/Town: <span>{item.village_town}</span>
                          </p>
                          <p className="mb-0" style={{ fontSize: "10px" }}>
                            SRN: <span>{item.sr_admission_no}</span>
                          </p>
                        </div>
                        <div className="col-sm-3 padding_0 gravity_center">
                          <div
                            className="text-center bg_card_blue_outline width_fill_parent"
                            style={{ margin: "1px", height: "83px" }}
                          >
                            <p className="mb-0">
                              Roll No: {item.student_roll_no}
                            </p>
                            <p>
                              <div
                                className="st-list-roll-n"
                                style={{
                                  background: "#fff",
                                  width: "35px",
                                  height: "35px",
                                  padding: "2px",
                                  borderRadius: "50%",
                                  margin: "2px auto",
                                  fontSize: "20px",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  className="ch-in cursor"
                                  checked={mSelectedStudent.some(
                                    (s) =>
                                      s.student_enrollment_no ===
                                      item.student_enrollment_no
                                  )}
                                  onChange={(e) =>
                                    handleStudentSelection(e, index)
                                  }
                                />
                              </div>
                            </p>
                            <h6 className="mb-0">
                              <span>
                                {item.section_id === "No"
                                  ? item.class_name
                                  : `${item.class_name}:${item.section_name}`}
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                <div
                  className="col-sm-12 text-center"
                  style={{ margin: "15px auto" }}
                  onClick={() => actionHandleSelected(mSelectedStudent)}
                >
                  <h3
                    style={{
                      background: "#129cd9",
                      color: "#fff",
                      padding: "7px",
                    }}
                  >
                    Done
                  </h3>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MdlSelectMultipleStudent;
